import _ from 'lodash';

export default function crudReducers({ resource, keyProperty, parentField, parentIdentifier }) {
  const actionTypes = {
    fetch: `FETCH_${resource}`,
    fetchPage: `FETCH_${resource}S_PAGE`,
    search: `SEARCH_${resource}S`,
    create: `CREATE_${resource}`,
    modify: `MODIFY_${resource}`,
    delete: `DELETE_${resource}`
  };
  return {
    content: contentReducer(actionTypes, keyProperty),
    currentPage: currentPageReducer(actionTypes, keyProperty),
    searchContent: searchContent(actionTypes, keyProperty),
    ...(parentField && {
      contentByParentKey: contentByParentKeyReducer(
        actionTypes,
        parentField,
        parentIdentifier,
        keyProperty
      )
    })
  };
}

const contentByParentKeyReducer =
  (actionTypes, parentField, parentIdentifier, keyProperty) =>
  (state = CURRENT_PAGE_INITIAL_STATE, action) => {
    const parentKey = parentIdentifier ? `${parentField}.${parentIdentifier}` : `${parentField}`;
    switch (action.type) {
      case actionTypes.fetchPage: {
        const { content, ...rest } = action.payload;
        return {
          ...rest,
          ..._.groupBy(action.payload.content, (item) => _.get(item, parentKey))
        };
      }
      case actionTypes.create: {
        const parentId = _.get(action.payload, parentKey);
        if (parentId) {
          return {
            ...state,
            [parentId]: (state[parentId] || []).concat([action.payload])
          };
        }
        return state;
      }
      case actionTypes.modify:
        {
          const parentId = _.get(action.payload, parentKey);
          if (parentId) {
            return {
              ...state,
              [parentId]: (state[parentId] || []).map((res) =>
                res[keyProperty] === action.payload[keyProperty] ? action.payload : res
              )
            };
          }
        }
        return state;
      case actionTypes.delete: {
        const parentId = _.get(action.payload, parentKey);
        if (parentId) {
          return {
            ...state,
            [parentId]: (state[parentId] || []).filter(
              (res) => res[keyProperty] !== action.payload[keyProperty]
            ),
            totalElements: state.totalElements - 1
          };
        }
        return state;
      }

      default:
        return state;
    }
  };

function contentReducer(actionTypes, keyProperty) {
  return (state = {}, action) => {
    switch (action.type) {
      case actionTypes.fetchPage:
      case actionTypes.search:
        return {
          ...state,
          ..._.keyBy(action.payload.content, keyProperty)
        };
      case actionTypes.delete:
        return {
          ...state,
          [action.payload[keyProperty]]: undefined
        };
      case actionTypes.fetch:
      case actionTypes.create:
      case actionTypes.modify:
        return {
          ...state,
          [action.payload[keyProperty]]: action.payload
        };
      default:
        return state;
    }
  };
}

export const CURRENT_PAGE_INITIAL_STATE = {
  content: [],
  number: 0,
  totalPages: 0,
  totalElements: 0,
  size: 20
};

function currentPageReducer(actionTypes, keyProperty) {
  return (state = CURRENT_PAGE_INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.fetchPage:
        return action.payload;
      case actionTypes.delete:
        return {
          ...state,
          content: state.content.filter((e) => e[keyProperty] !== action.payload[keyProperty]),
          totalElements: state.totalElements - 1
        };
      case actionTypes.create:
        return {
          ...state,
          content: state.content.concat([action.payload]),
          totalElements: state.totalElements + 1
        };
      case actionTypes.fetch:
      case actionTypes.modify:
        return {
          ...state,
          content: state.content.map((e) =>
            e[keyProperty] === action.payload[keyProperty] ? action.payload : e
          )
        };
      default:
        return state;
    }
  };
}

/**
 * Content of this reducers is restricted to the last search.
 *
 */
function searchContent(actionTypes, keyProperty) {
  return (state = {}, action) => {
    switch (action.type) {
      case actionTypes.search:
        return {
          ..._.keyBy(action.payload.content, keyProperty)
        };
      case actionTypes.delete:
        return {
          ...state,
          [action.payload[keyProperty]]: undefined
        };
      default:
        return state;
    }
  };
}
