import ResourceTypes from 'constants/ResourceTypes';
import crudReducers from 'reducers/utils';
import filtersReducer from 'reducers/utils/filters';
import { combineReducers } from 'redux';

const filtersDefault = { search: '' };

export default combineReducers({
  filters: filtersReducer({ resource: ResourceTypes.DATA_CATEGORY_IRI, filtersDefault }),
  ...crudReducers({
    resource: ResourceTypes.DATA_CATEGORY_IRI,
    keyProperty: 'uuid',
    parentField: 'dataCategory',
    parentIdentifier: 'uuid'
  })
});
