export const ResourceTypes = {
  /**
   * Logical Model
   */
  LOGICAL_NAMESPACE: 'LOGICAL_NAMESPACE',
  DATA_CATEGORY: 'DATA_CATEGORY',
  LOGICAL_FIELD: 'LOGICAL_FIELD',
  LOGICAL_RELATION: 'LOGICAL_RELATION',
  LOGICAL_PREDICATE: 'LOGICAL_PREDICATE',
  DATA_CATEGORY_IRI: 'DATA_CATEGORY_IRI',
  /**
   * Processing Registry
   * */
  DATA_ACTOR: 'DATA_ACTOR',
  PROCESSING: 'PROCESSING',
  TASK_ASSIGNMENT: 'TASK_ASSIGNMENT',
  TASK: 'TASK',
  /**
   * Physical Model
   */
  SYSTEM: 'SYSTEM',
  PHYSICAL_ENTITY: 'PHYSICAL_ENTITY',
  PHYSICAL_FIELD: 'PHYSICAL_FIELD',
  SYSTEM_ROUTINE: 'SYSTEM_ROUTINE',
  PHYSICAL_CONSTRAINT: 'PHYSICAL_CONSTRAINT',

  /**
   * Data Quality
   */
  QUALITY_CHECK: 'QUALITY_CHECK',
  QUALITY_SUITE: 'QUALITY_SUITE',
  QUALITY_RESULT: 'QUALITY_RESULT',
  QUALITY_PROBE_DEFINITION: 'QUALITY_PROBE_DEFINITION',
  QUALITY_PROBE_PROJECT: 'QUALITY_PROBE_PROJECT',
  PROFILING_MONITOR: 'PROFILING_MONITOR',
  PROFILING_METRIC_DEFINITION: 'PROFILING_METRIC_DEFINITION',
  PROFILING_METRIC_RECORD: 'PROFILING_METRIC_RECORD',
  PROFILING_METRIC_INCIDENT: 'PROFILING_METRIC_INCIDENT',
  PROFILING_METRIC_ANOMALY: 'PROFILING_METRIC_ANOMALY',

  /**
   * Issue Management
   */
  ISSUE: 'ISSUE',

  /**
   * Query Parser
   */
  QUERY_STATEMENT: 'QUERY_STATEMENT',
  QUERY_BUCKET: 'QUERY_BUCKET',

  /**
   * Consents
   */
  CONTRACT: 'CONTRACT',

  /**
   * Data Mesh
   */
  DATA_PRODUCT: 'DATA_PRODUCT',
  DATA_PRODUCT_PORT: 'DATA_PRODUCT_PORT',
  DATA_PRODUCT_DOMAIN: 'DATA_PRODUCT_DOMAIN',
  DATA_PRODUCT_STAGE: 'DATA_PRODUCT_STAGE',
  GOVERNANCE_POLICY: 'GOVERNANCE_POLICY',
  GOVERNANCE_POLICY_SUITE: 'GOVERNANCE_POLICY_SUITE',
  GOVERNANCE_POLICY_ADOPTION: 'GOVERNANCE_POLICY_ADOPTION',
  GOVERNANCE_POLICY_EVALUATION: 'GOVERNANCE_POLICY_EVALUATION',
  GOVERNANCE_POLICY_IMPLEMENTATION: 'GOVERNANCE_POLICY_IMPLEMENTATION'
};
//TODO add permissions and search to POLICIES

export const AdminPermissionsByResourceTypes = {
  LOGICAL_NAMESPACE: 'DATA_CATEGORIES_ADMIN',
  DATA_CATEGORY: 'DATA_CATEGORIES_ADMIN',
  LOGICAL_FIELD: 'DATA_CATEGORIES_ADMIN',
  LOGICAL_RELATION: 'DATA_CATEGORIES_ADMIN',
  DATA_ACTOR: 'DATA_ACTORS_ADMIN',
  PROCESSING: 'PROCESSINGS_ADMIN',
  TASK_ASSIGNMENT: 'TASKS_ADMIN',
  TASK: 'TASKS_ADMIN',
  SYSTEM: 'SYSTEMS_ADMIN',
  PHYSICAL_ENTITY: 'SYSTEMS_ADMIN',
  PHYSICAL_FIELD: 'SYSTEMS_ADMIN',
  SYSTEM_ROUTINE: 'SYSTEMS_ADMIN',
  PHYSICAL_CONSTRAINT: 'SYSTEMS_ADMIN',
  QUALITY_CHECK: 'QUALITY_ADMIN',
  QUALITY_SUITE: 'QUALITY_ADMIN',
  QUALITY_RESULT: 'QUALITY_ADMIN',
  QUALITY_PROBE_DEFINITION: 'QUALITY_ADMIN',
  QUALITY_PROBE_PROJECT: 'QUALITY_ADMIN',
  PROFILING_MONITOR: 'PROFILING_ADMIN',
  PROFILING_METRIC_DEFINITION: 'PROFILING_ADMIN',
  PROFILING_METRIC_RECORD: 'PROFILING_ADMIN',
  PROFILING_METRIC_INCIDENT: 'PROFILING_ADMIN',
  PROFILING_METRIC_ANOMALY: 'PROFILING_ADMIN',
  ISSUE: 'CAMPAIGNS_ADMIN',
  QUERY_STATEMENT: 'QUERY_PARSER_ADMIN',
  QUERY_BUCKET: 'QUERY_PARSER_ADMIN',
  CONTRACT: 'CONTRACTS_ADMIN',
  DATA_PRODUCT: 'DATA_PRODUCTS_ADMIN',
  DATA_PRODUCT_PORT: 'DATA_PRODUCTS_ADMIN',
  DATA_PRODUCT_DOMAIN: 'DATA_PRODUCTS_ADMIN',
  GOVERNANCE_POLICY: 'GOVERNANCE_POLICIES_ADMIN',
  GOVERNANCE_POLICY_SUITE: 'GOVERNANCE_POLICIES_ADMIN'
};

export const ViewerPermissionsByResourceTypes = {
  LOGICAL_NAMESPACE: 'DATA_CATEGORIES_VIEWER',
  DATA_CATEGORY: 'DATA_CATEGORIES_VIEWER',
  LOGICAL_FIELD: 'DATA_CATEGORIES_VIEWER',
  LOGICAL_RELATION: 'DATA_CATEGORIES_VIEWER',
  DATA_ACTOR: 'DATA_ACTORS_VIEWER',
  PROCESSING: 'PROCESSINGS_VIEWER',
  TASK_ASSIGNMENT: 'TASKS_VIEWER',
  TASK: 'TASKS_VIEWER',
  SYSTEM: 'SYSTEMS_VIEWER',
  PHYSICAL_ENTITY: 'SYSTEMS_VIEWER',
  PHYSICAL_FIELD: 'SYSTEMS_VIEWER',
  SYSTEM_ROUTINE: 'SYSTEMS_VIEWER',
  PHYSICAL_CONSTRAINT: 'SYSTEMS_VIEWER',
  QUALITY_CHECK: 'QUALITY_VIEWER',
  QUALITY_SUITE: 'QUALITY_VIEWER',
  QUALITY_RESULT: 'QUALITY_VIEWER',
  QUALITY_PROBE_DEFINITION: 'QUALITY_VIEWER',
  QUALITY_PROBE_PROJECT: 'QUALITY_VIEWER',
  PROFILING_MONITOR: 'PROFILING_VIEWER',
  PROFILING_METRIC_DEFINITION: 'PROFILING_VIEWER',
  PROFILING_METRIC_RECORD: 'PROFILING_VIEWER',
  PROFILING_METRIC_INCIDENT: 'PROFILING_VIEWER',
  PROFILING_METRIC_ANOMALY: 'PROFILING_VIEWER',
  ISSUE: 'CAMPAIGNS_VIEWER',
  QUERY_STATEMENT: 'QUERY_PARSER_VIEWER',
  QUERY_BUCKET: 'QUERY_PARSER_VIEWER',
  CONTRACT: 'CONTRACTS_VIEWER',
  DATA_PRODUCT: 'DATA_PRODUCTS_VIEWER',
  DATA_PRODUCT_PORT: 'DATA_PRODUCTS_VIEWER',
  DATA_PRODUCT_DOMAIN: 'DATA_PRODUCTS_VIEWER',
  GOVERNANCE_POLICY: 'GOVERNANCE_POLICIES_VIEWER',
  GOVERNANCE_POLICY_SUITE: 'GOVERNANCE_POLICIES_VIEWER'
};

export const EditorPermissionsByResourceTypes = {
  LOGICAL_NAMESPACE: 'DATA_CATEGORIES_EDITOR',
  DATA_CATEGORY: 'DATA_CATEGORIES_EDITOR',
  LOGICAL_FIELD: 'DATA_CATEGORIES_EDITOR',
  LOGICAL_RELATION: 'DATA_CATEGORIES_EDITOR',
  DATA_ACTOR: 'DATA_ACTORS_EDITOR',
  PROCESSING: 'PROCESSINGS_EDITOR',
  TASK_ASSIGNMENT: 'TASKS_EDITOR',
  TASK: 'TASKS_EDITOR',
  SYSTEM: 'SYSTEMS_EDITOR',
  PHYSICAL_ENTITY: 'SYSTEMS_EDITOR',
  PHYSICAL_FIELD: 'SYSTEMS_EDITOR',
  SYSTEM_ROUTINE: 'SYSTEMS_EDITOR',
  PHYSICAL_CONSTRAINT: 'SYSTEMS_EDITOR',
  QUALITY_CHECK: 'QUALITY_EDITOR',
  QUALITY_SUITE: 'QUALITY_EDITOR',
  QUALITY_RESULT: 'QUALITY_EDITOR',
  QUALITY_PROBE_DEFINITION: 'QUALITY_EDITOR',
  QUALITY_PROBE_PROJECT: 'QUALITY_EDITOR',
  PROFILING_MONITOR: 'PROFILING_EDITOR',
  PROFILING_METRIC_DEFINITION: 'PROFILING_EDITOR',
  PROFILING_METRIC_RECORD: 'PROFILING_EDITOR',
  PROFILING_METRIC_INCIDENT: 'PROFILING_EDITOR',
  PROFILING_METRIC_ANOMALY: 'PROFILING_EDITOR',
  ISSUE: 'CAMPAIGNS_EDITOR',
  QUERY_STATEMENT: 'QUERY_PARSER_EDITOR',
  QUERY_BUCKET: 'QUERY_PARSER_EDITOR',
  CONTRACT: 'CONTRACTS_EDITOR',
  DATA_PRODUCT: 'DATA_PRODUCTS_EDITOR',
  DATA_PRODUCT_PORT: 'DATA_PRODUCTS_EDITOR',
  DATA_PRODUCT_DOMAIN: 'DATA_PRODUCTS_EDITOR',
  GOVERNANCE_POLICY: 'GOVERNANCE_POLICIES_EDITOR',
  GOVERNANCE_POLICY_SUITE: 'GOVERNANCE_POLICIES_EDITOR'
};

export const SearchableResources = [
  ResourceTypes.DATA_CATEGORY,
  ResourceTypes.LOGICAL_FIELD,
  ResourceTypes.SYSTEM,
  ResourceTypes.PHYSICAL_ENTITY,
  ResourceTypes.PHYSICAL_FIELD,
  ResourceTypes.PROCESSING,
  ResourceTypes.DATA_ACTOR,
  ResourceTypes.TASK,
  ResourceTypes.QUALITY_SUITE,
  ResourceTypes.QUALITY_CHECK,
  ResourceTypes.QUERY_BUCKET,
  ResourceTypes.QUALITY_PROBE_DEFINITION,
  ResourceTypes.QUALITY_PROBE_PROJECT,
  ResourceTypes.DATA_PRODUCT,
  ResourceTypes.PROFILING_MONITOR,
  ResourceTypes.DATA_PRODUCT_DOMAIN
];

export default ResourceTypes;
