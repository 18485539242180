import { combineReducers } from 'redux';
import namespaces from './namespaces';
import predicates from './predicates';
import iris from './iris';

export default combineReducers({
  namespaces,
  predicates,
  iris
});
